<template>
    <div class="app">
        <div class="wrapper">
            <h1>Generate Lorem Ipsum</h1>
            <p>Use this tool to quickly generate your desired number of paragraphs of Lorem Ipsum. The text will automatically be copied to your clipboard.</p>
            <form>
                <div class="label">Number of paragraphs</div>
                <button class="number left" type="button" @click="decrease()" :disabled="count <= 1">-</button>
                <input readonly type="number" v-model="count">
                <button class="number right" type="button" @click="increase()">+</button>
                <button class="copy" type="button" @click="copyToClipboard()" ref="copyButton"><i class="material-icons">file_copy</i></button>
            </form>
            <div class="result">
              <template v-for="item in result">
                <p>
                  {{ item }}
                </p>
                <p>&nbsp;</p>
              </template>
            </div>
            <footer>
                <p>
                    &copy; <a href="https://naavi.com">Naavi</a> {{ currentYear }}
                </p>
            </footer>
        </div>
    </div>
</template>

<script>
    import lipsum from './loremipsum'


    export default {
        name: 'app',
        components: {

        },
        data() {
            return {
                count: null
            }
        },
        methods: {
            ready() {
                setTimeout(() => {
                    this.$refs.copyButton.click();
                });
                if(window.localStorage) {
                    const count = window.localStorage.getItem("count");
                    if(count && parseInt(count)) {
                        this.count = parseInt(count);
                    } else {
                        this.count = 5;
                    }
                } else {
                    this.count = 5;
                }
            },
            decrease() {
                this.count = Math.max(1, this.count - 1);
                this.copyToClipboard();
            },
            increase() {
                this.count = Math.min(150, this.count + 1);
                this.copyToClipboard();
            },
            copyToClipboard() {
                const el = document.createElement("div");
                el.innerHTML = this.copyResult;
                document.body.appendChild(el);
                window.getSelection().removeAllRanges()
                const range = document.createRange()
                range.selectNode(el)
                window.getSelection().addRange(range)
                const ret = document.execCommand("copy");
                document.body.removeChild(el);
                if(ret) {
                    this.$toasted.info("Copied to clipboard.");
                }
            }
        },
        computed: {
            split() {
                return lipsum.split("\n")
            },
            result() {
                return this.split.slice(0, this.count);
            },
            copyResult() {
                return `<p>${this.result.join("</p><p></p><p>")}</p>`
            },
          currentYear () {
              return new Date().getFullYear()
          }
        },
        watch: {
            count() {
                if(window.localStorage) {
                    window.localStorage.setItem("count", this.count);
                }
            }
        },
        mounted() {
            this.ready();
        }
    }
</script>

<style lang="scss">
    $darkGrey: #7E7E7C;
    $midGrey: #DBDBD8;
    $lightGrey: #F5F5F5;

    $black: #333333;

    .app {
        padding: 30px 0;
    }

    .wrapper {
        width: 1000px;
        margin: 0 auto;
    }

    p {
        color: $darkGrey;
        margin: 0 0;
        min-height: 20px;
    }

    form {
        margin-top: 30px;

        .label {
            font-size: 18px;
            margin-bottom: 10px;
        }

        button {
            background-color: $midGrey;
            color: $black;
            border: 0;
            padding: 16px;
            font-size: 20px;
            border-radius: 5px;
            cursor: pointer;

            &:focus {
                outline: none;
            }

            &.number {
                &.left {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &.right {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &.copy {
                margin-left: 20px;
                display: inline-block;
            }

            i.material-icons {
                font-size: 18px;
            }
        }

        input {
            background-color: $lightGrey;
            color: $black;
            border: 0;
            padding: 16px;
            font-size: 20px;
            max-width: 60px;

            &:focus {
                outline: none;
            }
        }
    }

    .result {
        margin: 20px 0;
        padding: 5px 20px;
        border: 1px solid $lightGrey;
        border-radius: 5px;
    }

    footer {
        text-align: center;
        font-size: 14px;

        p, a {
            color: $midGrey;
        }
    }

    .toast {
        &.toasted {
            background-color: $darkGrey !important;
            font-family: Roboto, sans-serif;
            font-size: 13px !important;
            font-weight: 500 !important;
        }
    }

    @media screen and (max-width: 1100px) {
        .app {
            padding: 10px 0;
        }

        .wrapper {
            width: auto;
            margin: 0 20px;
        }
    }
</style>
